// const swiper = new Swiper('.swiper', {
// 	// Optional parameters
// 	direction: 'horizontal',
// 	loop: true,
// 	centeredSlides: true,
// 	autoplay: {
// 		delay: 0,
// 	},
// 	speed: 3000,
// 	slidesPerView: 3,
// 	spaceBetween: 64,
// 	parallax: true,
// })

const isMobileFunc = () => {
	if (window.innerWidth <= 769) {
		return true
	}

	return false
}

let isMobile = isMobileFunc()

window.addEventListener('resize', () => {
	isMobile = isMobileFunc()
})

// Variable to store the Lenis smooth scrolling object
let lenis

// Selecting DOM elements
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollSmoother)

const contentElements = [...document.querySelectorAll('.sticky__content')]
const introLinks = [...document.querySelectorAll('.socials__link--intro')]

const totalContentElements = contentElements.length

// Initializes Lenis for smooth scrolling with specific properties
const initSmoothScrolling = () => {
	// if (ScrollTrigger.isTouch !== 1) {
	// 	ScrollSmoother.create({
	// 		// wrapper: '.main',
	// 		// content: '.main',
	// 		smooth: 1.5,
	// 		effects: true,
	// 	})
	// }

	lenis = new Lenis({
		lerp: 0.05, // Lower values create a smoother scroll effect
		smoothWheel: true, // Enables smooth scrolling for mouse wheel events
	})

	// Update ScrollTrigger each time the user scrolls
	lenis.on('scroll', () => ScrollTrigger.update())

	// Define a function to run at each animation frame
	const scrollFn = time => {
		lenis.raf(time) // Run Lenis' requestAnimationFrame method
		requestAnimationFrame(scrollFn) // Recursively call scrollFn on each frame
	}
	// Start the animation frame loop
	requestAnimationFrame(scrollFn)
}

// Function to handle scroll-triggered animations
const scroll = () => {
	contentElements.forEach((el, position) => {
		const isLast = position === totalContentElements - 1

		gsap
			.timeline({
				scrollTrigger: {
					trigger: el,
					start: 'top top',
					end: '+=100%',
					// end: 'bottom',
					scrub: true,
				},
			})
			.to(
				el,
				{
					ease: 'none',
					startAt: { filter: 'brightness(100%) contrast(100%)' },
					filter: isLast ? 'none' : 'brightness(40%) contrast(120%)',
					yPercent: isLast ? 0 : -15,
				},
				0
			)
			// Animate the content inner image
			.to(
				el.querySelector('.sticky__img--phone'),
				{
					ease: 'power1.in',
					yPercent: -40,
					rotation: -20,
				},
				0
			)
			.to(
				el.querySelector('.sticky__design--blue-last'),
				{
					ease: 'power1.in',
					y: isMobile ? '100vh' : '110vh',
					x: isMobile ? '50vw' : 150,
				},
				0
			)
			.to(
				el.querySelector('.sticky__text'),
				{
					ease: 'power1.inOut',
					yPercent: -50,
					rotation: -5,
				},
				0
			)
	})

	gsap
		.timeline({
			scrollTrigger: {
				trigger: '.intro',
				start: 'center center',
				end: '+=100%',
				scrub: true,
			},
		})
		.to(
			'.intro__down',
			{
				// ease: 'back.out',
				y: 70,
				rotate: 10,
			},
			0
		)
		.to(
			'.intro__title',
			{
				y: -50,
				rotate: -5,
			},
			0
		)
		.to(
			'.intro__link',
			{
				y: -70,
				rotate: 5,
			},
			0
		)
		.to(
			'.intro__socials',
			{
				y: -40,
				rotate: -10,
			},
			0
		)
		.fromTo(
			'.about__title',
			{
				opacity: 0,
			},
			{
				opacity: 1,
			},
			'<15%'
		)

	const aboutItems = gsap.utils.toArray('.about__people')

	about_tl = gsap.timeline({
		scrollTrigger: {
			trigger: '.about',
			start: 'top top+=70%',
			end: 'bottom bottom',
			scrub: true,
		},
	})

	aboutItems.forEach(item => {
		about_tl.fromTo(
			item,
			{
				opacity: 0,
				yPercent: 10,
			},
			{
				opacity: 1,
				yPercent: 0,
				// stagger: 0.4,
			},
			0
		)
	})

	about_tl.fromTo(
		'.about__text',
		{
			opacity: 0,
			yPercent: 15,
		},
		{
			opacity: 1,
			yPercent: 0,
		},
		'<70%'
	)

	gsap
		.timeline({
			scrollTrigger: {
				trigger: '.about',
				start: 'center center',
				end: '+=100%',
				scrub: true,
			},
		})
		.to(
			'.about__title',
			{
				y: -70,
				rotate: -5,
			},
			0
		)
		.to(
			'.about__peoples',
			{
				yPercent: -20,
				rotate: 3,
			},
			0
		)
		.to(
			'.about__text',
			{
				y: -50,
				rotate: -5,
			},
			0
		)

	gsap
		.timeline({
			scrollTrigger: {
				trigger: '.sticky',
				endTrigger: '.register',
				start: 'bottom bottom',
				end: 'bottom+=100% bottom',
				// end: isMobile ? '+=100%' : '+=120%',
				scrub: true,
			},
		})
		.fromTo(
			'.register__qr',
			{
				yPercent: -40,
				opacity: 0.5,
				rotate: -14,
			},
			{
				opacity: 1,
				yPercent: 0,
				rotate: 14,
			},
			0
		)
		.fromTo(
			'.register__text',
			{
				yPercent: -20,
				rotate: -10,
			},
			{
				rotate: 0,
				yPercent: 0,
			},
			0
		)
		.fromTo(
			'.register__btn',
			{
				rotate: 30,
				y: -50,
			},
			{
				rotate: 0,
				y: 0,
			},
			0
		)
		.fromTo(
			'.register__socials',
			{
				y: 50,
				opacity: 0.5,
			},
			{
				opacity: 1,
				y: 0,
			},
			0.1
		)

	gsap.fromTo(
		'.our__wrapper',
		{
			x: 400,
		},
		{
			x: 0,
			ease: 'none',
			scrollTrigger: {
				trigger: '.our__wrapper',
				start: 'top top+=100%',
				end: 'bottom bottom-=100%',
				scrub: true,
			},
		}
	)
}

// Initialization function
const init = () => {
	initSmoothScrolling() // Initialize Lenis for smooth scrolling
	scroll() // Apply scroll-triggered animations
}

document.addEventListener('DOMContentLoaded', () => {
	init()
})
